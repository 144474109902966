<template>
  <div>
    <v-card class="pt-1 mt-1" outlined>
      <v-card-title class="mb-n5">
        <p class="error--text text--darken-1">System Settings</p>
      </v-card-title>
      <v-divider class=""></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <h4 class="caption">Global System Settings</h4>
          <v-row>
            <v-col cols="12" md="3">
              <v-select
                v-model="editedItem.login_branded"
                :items="login_categories"
                label="Select Login Screen"
                item-text="title"
                item-value="id"
                color="grey"
                dense
                outlined
                class="d-flex align-end"
                :rules="selectRules"
              ></v-select>
            </v-col>

            <!-- Closing date  -->
            <v-col cols="12" sm="4" md="4">
              <v-menu
                ref="menu_closing_date"
                v-model="menu_closing_date"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editedItem.closing_date"
                    :value="computedClosingDate"
                    label="Prequalification Closing Date"
                    persistent-hint
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    color="grey"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="closingDateWatcher"
                  no-title
                  @change="menu_closing_date = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider class="mt-n4 mb-1"></v-divider>
      <v-card-actions>
        <!-- <v-btn
          color="grey darken-1"
          class="mr-auto mr-2 text--white text-capitalize"
          outlined
          >Skip <v-icon>mdi-skip-next</v-icon></v-btn
        >  -->
        <v-btn
          color="primary darken-1"
          class="ml-auto mr-2 text--white text-capitalize"
          @click="save"
          dark
          :loading="loading"
          >Update <v-icon>mdi-content-save-outline</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import CompanyService from "../../services/company.service";
import ConfigService from "../../services/config.service";

export default {
  data: (vm) => ({
    valid: true,
    login_categories: [
      {
        id: 1,
        title: "Default",
      },
      {
        id: 2,
        title: "Branded",
      },
    ],
    selectedBusinessCategory: [],
    remember: 0,
    timeout: 2000,
    loading: false,
    logoFileToBeUploaded: {},
    editedItem: {
      closing_date: vm.formatDate(new Date().toISOString().substr(0, 10)),
    },
    requiredRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    selectRules: [(v) => !!v || "Select is required"],
    closingDateWatcher: new Date().toISOString().substr(0, 10),
    menu_closing_date: false,
    config: {},
  }),
  name: "CompleteProfile",
  components: {},
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    loggedInUser() {
      return this.$store.state.auth.user;
    },
    user() {
      return this.$store.state.auth.user;
    },
    computedLogo() {
      return this.$store.state.auth.user.company.company_logo;
    },
    computedLogoFile() {
      return this.dataURLtoFile(
        this.$store.state.auth.user.company.company_logo,
        "logo"
      );
    },
    computedClosingDate() {
      return this.formatDate(this.config.prequalification_closing_date);
    },
    computedBranded() {
      return this.config.login_branded;
    },
  },
  watch: {
    closingDateWatcher() {
      this.editedItem.closing_date = this.formatDate(this.closingDateWatcher);
    },
  },
  created() {
    this.systemConfig();
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    readURL(file) {
      var reader = new FileReader();
      reader.onload = function (e) {
        localStorage.setItem("companylogo", e.target.result);
      };
      reader.readAsDataURL(file);
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    systemConfig() {
      return ConfigService.index().then(
        (response) => {
          setTimeout(() => {
            if (response.status == 200) {
              this.config = response.data.data;
              this.editedItem.login_branded = response.data.data.login_branded;
              this.editedItem.closing_date = response.data.data.prequalification_closing_date;
              console.log(this.editedItem.login_branded);
              this.dialog = false;
              this.pageReady = true;
            } else {
              console.log(response.data.error);
            }
          }, 1000);
        },
        (error) => {
          console.log(error);
        }
      );
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    reverseFormatDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("-");
      console.log(`${year}-${month}-${day}`);
      return `${year}-${month}-${day}`;
    },
    save() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        this.editedItem.id = this.user.company.id;
        this.editedItem.prequalification_closing_date = this.reverseFormatDate(this.editedItem.closing_date)
        console.log(this.editedItem);
        return CompanyService.updateSettings(this.editedItem).then(
          (response) => {
            console.log(response);
            if (response.status == 200) {
              this.$store.dispatch(
                "alert/success",
                "Settings updated successfuly!"
              );
              this.loading = false;
            } else {
              console.log(response.data.error);
              this.loading = false;
            }
          },
          (error) => {
            console.log(error);
            if (error.response.status == 401) {
              this.handleLogout();
            }
            this.loading = false;
          }
        );
      }
    },
  },
};
</script>
